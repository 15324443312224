import React from "react";
import { useLocation, Navigate } from "react-router";
import { useSelector } from "react-redux";
/*******************************
 * RequireAuth
 * exported to App.js
 * reference https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 ******************************/
export default function RequestAuth({ children }) {
  const user = useSelector((state) => state.user.permission);
  let location = useLocation();

  
  
  if(!user){
     // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={"/"} state={{from: location}} replace  />
  }

  return children;
}

import { db } from "./firebase_configuration";
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  addDoc,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

/*********************************************************
 * Firebase hook  with the closure tecnique (encapsulation)
 * Exported to Login.js
 *********************************************************/
export default function FirebaseModule() {
  ///////////////////////////////////////////////////////////////
  // Encapsulation tecnique (Closures) to use in conjunction with
  // the public functions starting in line 76
  // https://firebase.google.com/docs/firestore/query-data/get-data
  async function checkDataFromDatabase(pathCollec, userUID) {
    const ref = doc(db, `/${pathCollec}/${userUID}`);
    const snapshot = await getDoc(ref);
    // console.log(snapshot.exists());
    return snapshot; // returns a promise for later use to be iterated
  }
  // https://firebase.google.com/docs/firestore/manage-data/add-data
  async function addDataToDatabase(pathCollec, documentID, dataProvided) {
    localStorage.setItem("process", true);
    // documentID is the document (reference or name) in firebase
    await setDoc(doc(db, pathCollec, documentID), dataProvided);
    localStorage.setItem("process", false);
  }

  // creates a unique document id in firebase with addDoc
  // immedialtely updates the documents with field id and add the id value
  // https://firebase.google.com/docs/firestore/manage-data/add-data
  async function addDocWithIDToDatabase(pathCollection, dataProvided) {
    const ref = await addDoc(collection(db, pathCollection), dataProvided);
    const docId = ref.id;
    updateDocToDatabase(pathCollection, docId, {
      id: docId,
    });
  }

  //https://firebase.google.com/docs/firestore/query-data/get-data#get_all_documents_in_a_collection
  async function getAllDocsFromDatabase(pathCollection) {
    const ref = await getDocs(collection(db, pathCollection));
    return ref;
  }

  // ref: https://firebase.google.com/docs/firestore/query-data/queries#simple_queries
  // checks validation from the already data in the database from the id object
  // keyInDatabase works like a hashmap or dictionary, the key will return the value id: 'value'
  async function getDocFromDatabase(pathCollec, keyInDatabase, valueToCompare) {
    const docRef = collection(db, pathCollec);
    const q = query(docRef, where(keyInDatabase, "==", valueToCompare));
    const snapShotQuery = await getDocs(q);
    return snapShotQuery; // returns a promise.then(() => {})... it needs to be iterated again
  }
  // https://firebase.google.com/docs/firestore/query-data/queries#compound_and_queries
  async function getDocFromDatabaseCompound(
    pathCollec,
    key1,
    value1,
    key2,
    value2,
  ) {
    const docRef = collection(db, pathCollec);
    const q = query(
      docRef,
      where(key1, "==", value1),
      where(key2, "==", value2),
    );
    const snapShotQuery = await getDocs(q);
    return snapShotQuery; // returns a promise.then(() => {})... it needs to be iterated again
  }
  async function getDocFromDatabaseGreaterThan(pathCollec, key1, value1) {
    const docRef = collection(db, pathCollec);
    const q = query(docRef, where(key1, ">=", value1));
    const snapShotQuery = await getDocs(q);
    return snapShotQuery; // returns a promise.then(() => {})... it needs to be iterated again
  }

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update-data
  async function updateDocToDatabase(pathCollection, documentId, data) {
    const documentRef = doc(db, pathCollection, documentId);
    const ref = await updateDoc(documentRef, data);
    return ref;
  }

  async function deleteDocFromDatabase(pathCollection, documentId) {
    const ref = await deleteDoc(doc(db, pathCollection, documentId));
    return ref;
  }
  ///////////////////////////////////////////////////////////////
  // will invoke an instance of the closure module,
  function checkDataInFirebase(pathCollec, userUID) {
    return checkDataFromDatabase(pathCollec, userUID);
  }

  function addDataInFirebase(pathCollec, valueID, dataProvided) {
    return addDataToDatabase(pathCollec, valueID, dataProvided);
  }

  function getAllDocsFromFirebase(pathCollection) {
    return getAllDocsFromDatabase(pathCollection);
  }

  function getDocInFirebase(pathCollec, keyInDatabase, valueToCompare) {
    return getDocFromDatabase(pathCollec, keyInDatabase, valueToCompare);
  }
  function getDocFromFirebaseCompound(pathCollec, key1, value1, key2, value2) {
    return getDocFromDatabaseCompound(pathCollec, key1, value1, key2, value2);
  }
  function getDocFromFirebaseGreaterThan(
    pathCollec,
    key1,
    value1,
    key2,
    value2,
  ) {
    return getDocFromDatabaseGreaterThan(
      pathCollec,
      key1,
      value1,
      key2,
      value2,
    );
  }

  function addDocumentWithFirebaseID(pathCollection, dataProvided) {
    return addDocWithIDToDatabase(pathCollection, dataProvided);
  }

  function updateDocToFirebase(pathCollection, documentId, data) {
    return updateDocToDatabase(pathCollection, documentId, data);
  }

  function deleteDocFromFirebase(pathCollection, documentId) {
    return deleteDocFromDatabase(pathCollection, documentId);
  }

  return {
    checkDataInFirebase,
    addDataInFirebase,
    getAllDocsFromFirebase,
    getDocInFirebase,
    addDocumentWithFirebaseID,
    updateDocToFirebase,
    getDocFromFirebaseCompound,
    deleteDocFromFirebase,
    getDocFromFirebaseGreaterThan,
  };
}

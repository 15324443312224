import React, { useState, useEffect, Suspense, lazy } from "react";
import Login from "./components/login/Login";
import { Route, Routes, BrowserRouter } from "react-router";
import RequestAuth from "./RequestAuth";

/////////////////////
//code splitting
const Main = lazy(() => import("./components/main/Main.js"));
const Orders = lazy(() => import("./orders/Orders.js"));
const Exercises = lazy(() => import("./components/exercises/Exercises.js"));
const Programs = lazy(() => import("./components/programs/Programs.js"));
const CorePackages = lazy(() =>
  import("./components/core-packages/CorePackages.js"),
);
const Profile = lazy(() => import("./components/profile/Profile.js"));
const Notifications = lazy(() =>
  import("./components/notifications/NotificationSystem.js"),
);
const Clients = lazy(() => import("./components/clients/Clients.js"));
const Physiotherapy = lazy(() =>
  import("./components/physiotherapy/Physiotherapy.js"),
);
const Inventory = lazy(() => import("./components/inventory/Inventory.js"));
const UserLogin = lazy(() => import("./components/login/UserLogin.js"));
const Nutri = lazy(() => import("./components/nutrition/Nutri.js"));

/****************
 * App
 **************/
function App() {
  const [isOIS, setIsOIS] = useState(false);
  const [message, setMessage] = useState("");

  // console.log(currentUser[0].fullName);

  // added this logic to avoid the property fullName undefined error from
  // currentUser[0].fullName when array is deleted from redux
  // fullName is not hardcoded in redux, it is created when the
  // function is called in Login.js
  // useEffect(() => {
  //   const isOIS =
  //     /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  //   if (isOIS) {
  //     setIsOIS(true);
  //   }

  //   return () => {};
  // }, []);

  //  Disable right-click using useEffect hook
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  ////////////////////////////////////////////////
  // checks if there is internet connectivity
  // function onHandlerInternet() {
  //   if (!navigator.onLine) {
  //     setMessage("No hay conneccion");
  //   }
  // }

  return (
    <BrowserRouter
      future={{
        v7_relativeSplatPath: true,
      }}
    >
      <Suspense
        fallback={
          <div style={{ width: "100%", textAlign: "center", marginTop: "1em" }}>
            Loading.....
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/main"
            element={
              <RequestAuth>
                <Main />
              </RequestAuth>
            }
          />
          <Route
            path="/inventory"
            element={
              <RequestAuth>
                <Inventory />
              </RequestAuth>
            }
          />
          <Route
            path="/orders"
            element={
              <RequestAuth>
                <Orders />
              </RequestAuth>
            }
          />
          <Route
            path="/nutri"
            element={
              <RequestAuth>
                <Nutri />
              </RequestAuth>
            }
          />
          <Route
            path="/exercises"
            element={
              <RequestAuth>
                <Exercises />
              </RequestAuth>
            }
          />
          <Route
            path="/clients"
            element={
              <RequestAuth>
                <Clients />
              </RequestAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequestAuth>
                <Profile />
              </RequestAuth>
            }
          />
          <Route
            path="/notifications"
            element={
              <RequestAuth>
                <Notifications />
              </RequestAuth>
            }
          />
          <Route
            path="/userlogin"
            element={
              <RequestAuth>
                <UserLogin />
              </RequestAuth>
            }
          />
          <Route
            path="/programs"
            element={
              <RequestAuth>
                <Programs />
              </RequestAuth>
            }
          />
          <Route
            path="/corepackages"
            element={
              <RequestAuth>
                <CorePackages />
              </RequestAuth>
            }
          />
          <Route
            path="/physio"
            element={
              <RequestAuth>
                <Physiotherapy />
              </RequestAuth>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
